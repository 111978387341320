import { apiHomeService, apiUserService, apiOccasionService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class AppService {
  static getOpsCenterInfo(...extraParams) {
    return apiHomeService({
      method: 'GET',
      url: setEndPoint(endPoints.getOpsInfo, ...extraParams),
    });
  }

  static getAllOpsCenter(...extraParams) {
    return apiUserService({
      method: 'GET',
      url: setEndPoint(endPoints.getAllOpsCenters, ...extraParams),
    });
  }

  static getOccasionsList(params, ...extraParams) {
    return apiOccasionService({
      method: 'GET',
      url: setEndPoint(endPoints.getOccasionsList, ...extraParams),
      params,
    });
  }

  static addNewOccasion(data, ...extraParams) {
    return apiOccasionService({
      method: 'POST',
      url: setEndPoint(endPoints.getOccasionsList, ...extraParams),
      data,
    });
  }

  static editOccasion(data, ...extraParams) {
    return apiOccasionService({
      method: 'PUT',
      url: setEndPoint(endPoints.getEditOccasion, ...extraParams),
      data,
    });
  }

  static getOccasionById(...extraParams) {
    return apiOccasionService({
      method: 'GET',
      url: setEndPoint(endPoints.getEditOccasion, ...extraParams),
    });
  }

  static getScannedList(...extraParams) {
    return apiOccasionService({
      method: 'GET',
      url: setEndPoint(endPoints.getScannedList, ...extraParams),
    });
  }

  static getScanQrCode(data, ...extraParams) {
    return apiOccasionService({
      method: 'POST',
      url: setEndPoint(endPoints.scanQrCode, ...extraParams),
      data,
    });
  }

  static getSearchByPhone(params, ...extraParams) {
    return apiOccasionService({
      method: 'GET',
      url: setEndPoint(endPoints.searchByPhone, ...extraParams),
      params,
    });
  }

  static getSendWhatsapp(data, ...extraParams) {
    return apiOccasionService({
      method: 'POST',
      url: setEndPoint(endPoints.sendWhatapp, ...extraParams),
      data,
    });
  }

  static getSendBorchor(data, ...extraParams) {
    return apiOccasionService({
      method: 'POST',
      url: setEndPoint(endPoints.sendBorchor, ...extraParams),
      data,
    });
  }

  static getUserByToken(...extraParams) {
    return apiOccasionService({
      method: 'GET',
      url: setEndPoint(endPoints.getUserByToken, ...extraParams),
    });
  }

  static getConfirmAttend(data, ...extraParams) {
    return apiOccasionService({
      method: 'POST',
      url: setEndPoint(endPoints.getConfirmAttend, ...extraParams),
      data,
    });
  }

  static getDataByOwnerToken(...extraParams) {
    return apiOccasionService({
      method: 'GET',
      url: setEndPoint(endPoints.getDataByOwner, ...extraParams),
    });
  }
  static editInvitee(data, ...extraParams) {
    return apiOccasionService({
      method: 'PUT',
      url: setEndPoint(endPoints.editInvitee, ...extraParams),
      data,
    });
  }

  static addNewInvitee(data, ...extraParams) {
    return apiOccasionService({
      method: 'POST',
      url: setEndPoint(endPoints.addNewInvitee, ...extraParams),
      data,
    });
  }

  static deleteInvitee(data, ...extraParams) {
    return apiOccasionService({
      method: 'DELETE',
      url: setEndPoint(endPoints.editInvitee, ...extraParams),
      params: data,
    });
  }

  static getLogin(data, ...extraParams) {
    return apiOccasionService({
      method: 'POST',
      url: setEndPoint(endPoints.getLogin, ...extraParams),
      data,
    });
  }

  static getExportPdf(...extraParams) {
    return apiOccasionService({
      method: 'GET',
      url: setEndPoint(endPoints.getExportPdf, ...extraParams),
      responseType: 'blob',
    });
  }

  static getPollingData(...extraParams) {
    return apiOccasionService({
      method: 'GET',
      url: setEndPoint(endPoints.getPollingData, ...extraParams),
    });
  }

  static getUpdatePolling(data, ...extraParams) {
    return apiOccasionService({
      method: 'POST',
      url: setEndPoint(endPoints.getPollingData, ...extraParams),
      data,
    });
  }

  static getPollByOccasionId(...extraParams) {
    return apiOccasionService({
      method: 'GET',
      url: setEndPoint(endPoints.getPollByOccasionId, ...extraParams),
    });
  }

  static getUndeliveredNumbers(...extraParams) {
    return apiOccasionService({
      method: 'GET',
      url: setEndPoint(endPoints.getUndeliveredNumbers, ...extraParams),
    });
  }

  static deleteOccasion(...extraParams) {
    return apiOccasionService({
      method: 'DELETE',
      url: setEndPoint(endPoints.deleteOccasionById, ...extraParams),
    });
  }

  //cover
  static getScannedInvitees(...extraParams) {
    return apiOccasionService({
      method: 'GET',
      url: setEndPoint(endPoints.getScannedInvitees, ...extraParams),
    });
  }

  static getCheckIn(data, ...extraParams) {
    return apiOccasionService({
      method: 'PUT',
      url: setEndPoint(endPoints.getCheckIn, ...extraParams),
      data,
    });
  }

  static getCheckOutbyId(...extraParams) {
    return apiOccasionService({
      method: 'PUT',
      url: setEndPoint(endPoints.getCheckOutById, ...extraParams),
    });
  }

  static getCheckOutByCover(data, ...extraParams) {
    return apiOccasionService({
      method: 'PUT',
      url: setEndPoint(endPoints.getCheckOutByCover, ...extraParams),
      data,
    });
  }

  static getCoverNotes(data, ...extraParams) {
    return apiOccasionService({
      method: 'POST',
      url: setEndPoint(endPoints.getCoverNotes, ...extraParams),
      data,
    });
  }

  static getExportCoversReport(...extraParams) {
    return apiOccasionService({
      method: 'GET',
      url: setEndPoint(endPoints.getExportCoverPdf, ...extraParams),
      responseType: 'blob',
    });
  }

  static getFetchCoversByToken(...extraParams) {
    return apiOccasionService({
      method: 'GET',
      url: setEndPoint(endPoints.getCoversDataByToken, ...extraParams),
    });
  }
}

export default AppService;
