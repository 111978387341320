import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SectionLoader from '../components/shared/SectionLoader';
import { Button, Divider } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { toast } from 'react-toastify';
import AppErrorBoundary from '../components/shared/AppErrorBoundary';
//API
import AppService from '../services/AppService';

const ApologizePage = () => {
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [userData, setUserData] = useState('');
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [isAttended, setIsAttended] = useState(false);
  const params = useParams();

  const fetchUserData = async (userToken) => {
    setIsLoaderOpen(true);
    try {
      const res = await AppService.getUserByToken(userToken);
      setUserData(res?.data);
      setIsConfirmed(res?.data?.isTokenUsed);
      setIsRejected(res?.data?.isRejected);
      setIsAttended(res?.data?.isAttended);
      setIsLoaderOpen(false);
    } catch (err) {
      setIsLoaderOpen(false);
    }
  };

  useEffect(() => {
    fetchUserData(params?.id);
  }, []);

  const handleConfirm = async (isConfirmed) => {
    const dataBody = {
      accept: isConfirmed,
    };
    setIsBtnDisabled(true);
    try {
      await AppService.getConfirmAttend(dataBody, userData?.id);
      setTimeout(() => {
        fetchUserData(params?.id);
      }, 200);
      setIsBtnDisabled(false);
    } catch (err) {
      toast.error(err?.response?.data?.message ?? 'حدث خطأ');
      setIsBtnDisabled(false);
    }
  };

  return (
    <AppErrorBoundary>
      <div className="apologize-container">
        {isLoaderOpen ? (
          <SectionLoader />
        ) : (
          <>
            <div>
              <div className="apologize-wrapper">
                <h3 className="welcome-msg">حياكم اللّه</h3>
                <p className="invitee-name">{userData?.name}</p>
                <div className="invitees-count">
                  <div>اجمالي عدد الدعوات</div>
                  <Divider orientation="vertical" style={{ backgroundColor: 'white' }} />
                  <span>{userData?.qrCodes?.length}</span>
                </div>
                {isConfirmed ? (
                  userData?.isRejected ? (
                    <div className="apologize-header">تم الاعتذار عن الحضور</div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Button
                        style={{ backgroundColor: '#249504', color: 'white', margin: '20px 0px' }}
                        variant="contained"
                      >
                        تم تأكيد الحضور
                      </Button>

                      <img
                        style={{ width: 85, borderRadius: 20 }}
                        src="https://imagecdn.prod.floward.com/web/files/tickets/826a812b-2cd4-4cf0-b2fa-87b019f05c8b.jpg"
                      />
                    </div>
                  )
                ) : (
                  <>
                    {!isAttended ? (
                      <AppErrorBoundary>
                        {userData && (
                          <div>
                            <Button
                              style={{ backgroundColor: '#249504', color: 'white', margin: 5 }}
                              variant="contained"
                              onClick={() => handleConfirm(true)}
                              endIcon={<CheckCircleIcon style={{ marginRight: 7, marginTop: 3 }} />}
                              disabled={isBtnDisabled}
                            >
                              تأكيد الحضور
                            </Button>
                            <Button
                              style={{ margin: 5, backgroundColor: '#A91B0D', color: 'white' }}
                              variant="contained"
                              onClick={() => handleConfirm(false)}
                              disabled={isBtnDisabled}
                            >
                              الاعتذار
                            </Button>
                          </div>
                        )}
                      </AppErrorBoundary>
                    ) : (
                      <div className="confirm-header">تم الحضور</div>
                    )}
                  </>
                )}
                {isConfirmed && !isRejected && (
                  <Button
                    variant="contained"
                    type="submit"
                    onClick={() => setIsConfirmed(false)}
                    color="default"
                    className="return-btn"
                    size="small"
                  >
                    تراجع
                  </Button>
                )}
              </div>
              {userData?.addressName && userData?.latitude !== 0 && userData?.longitude !== 0 && (
                <div className="location-btn-wrapper">
                  <a
                    className="location-btn"
                    href={`https://www.google.com/maps/?q=${userData?.latitude},${userData?.longitude}`}
                  >
                    اضغط لموقع الحفل
                  </a>
                </div>
              )}
            </div>
            <div style={{ textAlign: 'center' }}>
              <img
                className="header-ops-img"
                src={
                  'https://imagecdn.prod.floward.com/web/files/tickets/131ba08f-54fd-4d95-b6c4-dd13354d646e.png'
                }
                style={{ width: 140, borderRadius: 5, paddinTop: 30 }}
              />
            </div>
          </>
        )}
        <AppErrorBoundary>
          <p className="copy-rights">
            {' '}
            جميع الحقوق محفوظة لشركة دعوة لتنظيم المعارض و المؤتمرات و المعسكرات الرياضية ©{' '}
            {new Date()?.getFullYear()}
          </p>
        </AppErrorBoundary>
      </div>
    </AppErrorBoundary>
  );
};

export default ApologizePage;
